<template>
	<div ref="qrPanel" class="qr-panel background-dark">
		<div class="row">
			<div class="columns column12">
				<h2>{{ bookingForm.bookFormHeader }}</h2>
			</div>
		</div>
		<div class="row">
			<div class="columns column12">
				<div class="qr-wrapper">
					<div class="qr-full">
						<client-only>
							<div class="booking qr-form flex-row">
								<div class="qr-section">
									<date-picker
										v-model:value="state.arrival"
										:clearable="false"
										:disabled-date="disableArrivalDates"
										value-type="format"
										format="YYYY-MM-DD"
										:placeholder="$t('formArrival')"
										class=""
										:lang="datepickerLang"
										@pick="pickArrival"
									>
										<template #icon-calendar> &nbsp; </template>
									</date-picker>
								</div>
								<div class="qr-section">
									<date-picker
										v-model:value="state.departure"
										:open="state.openDeparture"
										:disabled-date="disableDepartureDates"
										value-type="format"
										format="YYYY-MM-DD"
										:placeholder="$t('formDeparture')"
										:default-value="state.pickedArrivalDate ?? new Date()"
										class=""
										:lang="datepickerLang"
										@open="state.openDeparture = true"
										@close="state.openDeparture = false"
									>
										<template #icon-calendar> &nbsp; </template>
									</date-picker>
								</div>
								<book-button
									class="qr-section button"
									:arrival="state.arrival"
									:departure="state.departure"
									:hotelid="hotelId"
									:packageid="packageId"
								>
									{{ $t('checkAvailability') }}
								</book-button>
							</div>
						</client-only>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import datePickerTranslations from '~/config/datePicker.js';

const { locale } = useI18n();

defineProps({
	hotelId: { type: String, default: '' },
	bookingForm: { type: Object, default: () => ({}) },
	packageId: { type: String, default: '' },
});

const state = reactive({
	arrival: null,
	departure: null,
	openDeparture: false,
	pickedArrivalDate: null,
});

const qrPanel = ref(null);

const datepickerLang = computed(() => datePickerTranslations[locale] ?? datePickerTranslations.en);

const pickArrival = (date) => {
	state.departure = '';
	state.pickedArrivalDate = date;
	state.openDeparture = true;
};

const disableArrivalDates = (date) => {
	const today = new Date();

	return date < today.setDate(today.getDate() - 1);
};

const disableDepartureDates = (date) => {
	if (!state.pickedArrivalDate) {
		return disableArrivalDates(date);
	}

	return date <= state.pickedArrivalDate;
};

onMounted(() => {
	qrPanel.value.classList.add('mounted');
});
</script>

<style lang="scss">
.mx-calendar-content .cell.active {
	background-color: var(--primary-color);
}

.mx-datepicker {
	width: 100% !important;
}
</style>

<style lang="scss" scoped>
/* QR-Panel */
.qr-panel {
	margin: 0 auto;
	text-align: center;
	padding: 50px 0;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;

	.row {
		width: 1180px;
		display: inline-block;
		float: none;
		max-width: 96%;
	}

	h2,
	h3 {
		margin: 0 0 25px;
		color: #fff;
	}
}

.qr-full,
.qr-form {
	width: 100%;
}

.qr-wrapper {
	width: 100%;
	margin: 0 auto;
	max-width: 880px;

	.flex-row {
		justify-content: space-between;
		flex-wrap: nowrap;
	}
}

.qr-section {
	padding: 0;
	width: 26%;
	position: relative;

	:deep(input:not(.button)),
	:deep(select) {
		appearance: none;
		border: 1px solid #e4ebed;
		box-shadow: none;
		color: var(--primary-text-color);
		font-size: 16px;
		font-weight: 400;
		background: none;
		position: relative;
		z-index: 1;
		cursor: pointer;
		padding: 0 15px;
		height: 62px;
		line-height: 62px;
		width: 100%;
		border-radius: 0;

		&.location,
		&.mx-input {
			padding-right: 25px;
			background: url('~/assets/images/icon-form-dropdown.png') no-repeat center right 10px #fff;
			background-size: 12px;
		}

		&::placeholder {
			color: var(--primary-text-color);
		}

		&:focus {
			outline: #fff;
		}
	}

	&.button {
		padding: 0 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 44%;
		height: 62px;
		line-height: 62px;
	}
}

@media (max-width: 960px) {
	.qr-panel {
		display: none;
	}
}
</style>
